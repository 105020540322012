/* Main container for package cards */
.packages-container {
    text-align: center;
    /* Removed unused background image */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 50px;
    gap: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Styling for the package heading */
.packages-container h1 {
    color: #ffffff;
}

/* Section Headers */
.packages-container h2 {
    color: #ffffff;
    margin: 40px 0 20px;
    font-size: 2em;
}

/* Adjust spacing between package sections */
.packages+h2 {
    margin-top: 60px;
}

/* Background styles for silver package card */
.silver-bg {
    flex-shrink: 0;
    background-image: url(../images/silver-bg.png);
    background-position: center;
}

/* Background styles for gold package card */
.gold-bg {
    flex-shrink: 0;
    background-image: url(../images/gold-bg.png);
    background-position: center;
}

/* Background styles for platinum package card */
.platinum-bg {
    flex-shrink: 0;
    background-image: url(../images/platinum-bg.png);
    background-size: auto;
    background-position: center;
}

/* Background styles for wedding package card */
.wedding-bg {
    flex-shrink: 0;
    background-image: url(../images/wedding-bg.png);
    background-position: center;
}

/* Background styles for premium wedding package card */
.premium-wedding-bg {
    flex-shrink: 0;
    background-image: url(../images/premium-wedding-bg.png);
    background-position: center;
}

/* Common card styles for all packages */
.package-card {
    height: 650px;
    width: 250px;
    margin: 150px;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
}

/* Individual styles for silver package card */
.package-card.silver {
    border-radius: 25px;
    border: 3px solid #000;
    background: linear-gradient(180deg, #C4CDD6 10.46%, #262C33 103.69%);
}

/* Individual styles for gold package card */
.package-card.gold {
    border-radius: 25px;
    border: 3px solid #E1BB66;
    background: linear-gradient(180deg, #F7E199 10.46%, #9A671F 103.69%);
}

/* Individual styles for platinum package card */
.package-card.platinum {
    border-radius: 25px;
    border: 3px solid #FBFBFB;
    background: linear-gradient(180deg, #EDEEEB 10.46%, #464C50 103.69%);
}

/* Individual styles for wedding package card */
.package-card.wedding {
    border-radius: 25px;
    border: 3px solid #000;
    background: linear-gradient(180deg, #cdbea5 10.46%, #8a7a62 103.69%);
}

/* Individual styles for premium wedding package card */
.package-card.premium-wedding {
    border-radius: 25px;
    border: 3px solid #000;
    background: linear-gradient(180deg, #9c8463 10.46%, #6d5d47 103.69%);
}

/* Container for the package icon, allows absolute positioning */
.package-icon-container {
    width: 100%;
    position: absolute;
    top: -125px;
    transition: transform 0.3s ease;
}

/* Slight movement effect on hover for the icons */
.package-icon-container:hover {
    transform: translateY(-5px);
}

/* Styling for the package icons */
.package-icon {
    width: 250px;
    height: 250px;
    margin: 0 auto;
    display: block;
    border-radius: 50%;
}

/* Package pricing typography styles */
.package-pricing {
    padding: 85px 0 0 0;
    color: #333;
    font-family: 'Arial', sans-serif;
}

/* Container for the details of each package */
.package-details {
    min-height: 374px;
    max-height: 375px;
    font-family: 'Verdana', sans-serif;
}

/* Flex container for all package cards */
.packages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Scale up package card on hover */
.package-card:hover {
    transform: scale(1.05);
}

/* Styling for package titles */
.package-title {
    font-family: 'Pacifico', cursive;
    font-size: 24px;
}

/* Common typography styles for pricing and details */
.package-pricing,
.package-details {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 16px;
}

/* Responsive styles for screens smaller than 768px */
@media all and (max-width: 768px) {
    .packages {
        flex-direction: column;
        align-items: center;
    }

    .silver-bg,
    .gold-bg,
    .platinum-bg,
    .wedding-bg,
    .premium-wedding-bg {
        width: 410px;
    }

    .package-card {
        margin: 150px auto;
    }

    .packages+h2 {
        margin-top: 40px;
    }
}

/* Responsive styles for screens smaller than 1024px */
@media all and (max-width: 1320px) {
    .packages {
        flex-direction: column;
        align-items: center;
    }
}