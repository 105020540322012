/* Main container for the home section with flexible column layout */
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;  /* Auto height for dynamic content fit */
    padding: 50px 20px; /* Uniform padding */
    background-image: url(../images/bg.png); /* Full-screen background image */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    color: #feffdc; /* Contrast text color for better readability */
}

/* Title styling for home sections */
.home-title {
    font-size: 2.5em;
    color: #feffdc;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 3px solid #feffdc; /* Highlighting with a bottom border */
    padding-bottom: 10px;
    text-align: center;
}

/* Styling for the main content text */
.home-content {
    font-size: 1em;
    text-align: center;
    max-width: 800px; /* Maximum width for content readability */
    margin: 20px auto; /* Centering content block */
    line-height: 1.5; /* Line height for better text flow */
}

.home-content a {
    color: #feffdc; /* Link color for better contrast */
    font-size: 1.2em;
    text-decoration: none;
    border-bottom: 1px dotted #feffdc; /* Subtle separator for links */
}

/* Button styles for call-to-action */
.home-button {
    background-color: #f4f4f4;
    color: #022133;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

/* Hover effect for buttons */
.home-button:hover {
    background-color: #ffffff;
    color: #022133;
}

/* List styling */
ul {
    list-style: none;
    padding: 0;
}

/* List item styling */
li {
    padding: 10px 0;
    font-size: 1.2em;
    border-bottom: 1px dotted #f4f4f4; /* Subtle separator for items */
}

/* Container for the logo */
.logo-container {
    display: flex;
    justify-content: center;
    mix-blend-mode: lighten;
    align-items: center;
    height: 100vh;
    background: #022133; /* Dark background for logo emphasis */
    margin-top: 50px;
}

/* Responsive logo styling */
.logo-container img {
    width: 100%;
    max-width: 900px; /* Max width to ensure logo is not too large */
    height: auto;
    margin: 20px;
}

/* Responsive styling for smaller devices */
@media all and (max-width: 768px) {
    .home-title {
        font-size: 2em; /* Adjust title size on smaller screens */
    }

    .home-content {
        font-size: 0.875em; /* Adjust content size for readability */
    }
}

@media all and (max-width: 768px) {
    .home-container {
        padding: 20px 10px; /* Reduce padding on smaller screens */
    }

    .text-container {
        margin: 10px auto; /* Adjust text container margin */
    }
}

@media all and (max-width: 768px) {
    .logo-container img {
        max-width: 500px; /* Adjust logo max width on smaller screens */
    }
}
