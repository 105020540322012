/* Main container for the footer that dictates its height, color, and layout */
.custom-footer {
    width: 100%;
    /* Full width */
    height: 100px;
    background: #57575700;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    /* Stack content vertically */
    justify-content: center;
    /* Center content vertically */
    align-items: center;
    /* Center content horizontally */
    flex-shrink: 0;
    /* Prevent the footer from shrinking in flex container */
}

/* Container for the logos within the footer */
.footer-logos {
    display: inline-flex;
    /* Align logos horizontally */
    align-items: center;
    /* Center logos vertically */
    gap: 10px;
    /* Spacing between each logo */
    padding-top: 10px;
    /* Space from top of footer to logos */
    margin-bottom: 10px;
    /* Space from logos to footer text */
}

.footer-logos a {
    transition: transform 0.2s ease-in-out;
}

.footer-logos a:hover {
    transform: scale(1.2);
}

.footer-logos svg:hover path {
    fill: #f0f0f0;
    /* slightly lighter on hover */
}

/* Styling for the text inside the footer */
.footer-text {
    color: #ffffff;
    /* White text color for visibility on dark background */
    font-size: 14px;
    /* Size of the footer text */
    font-weight: 400;
    /* Normal font weight */
    line-height: 21px;
    /* Spacing between lines of text */
    margin: 0;
    /* Reset default margins */
    text-align: center;
    /* Center-align the text */
}