nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(2, 33, 51);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 1000;
}

.logos {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.Links {
    display: flex;
    gap: 2rem;
}

.Links a {
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
}

.hamburger {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: rgb(2, 33, 51);
    width: 100%;
    max-width: 300px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
    z-index: 1000;
    border-radius: 0 0 4px 4px;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
}

.dropdown-content.active {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px 0;
}

.dropdown-content a {
    color: white;
    padding: 15px 20px;
    text-decoration: none;
    width: 100%;
    text-align: center;
    transition: background-color 0.3s;
}

.dropdown-content a:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 768px) {
    .Links {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .dropdown-content {
        position: fixed;
        right: 0;
        width: 100%;
        max-width: 100%;
        top: 73px;
        height: auto;
        max-height: calc(100vh - 73px);
    }
}