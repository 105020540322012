:root {
  --underline-width: 100%; /* This sets the width of the underline */
}
/* General Reset and Basic Styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  padding: 0;
  background-color: #022133;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  scroll-behavior: smooth;
}

/* Navbar styling */
nav {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between; /* updated from space-around */
  align-items: center;
  background-color: #022133; /* slightly lighter dark background */
  height: 50px; /* fixed height for the navbar */
  position: fixed; /* makes the navbar stay on top */
  top: 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 25px;
  border: 1px solid #888BDA;
  z-index: 1000; /* ensures the navbar is above other content */
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); /* slightly more pronounced shadow for depth */
}

.Links a{
  color: #ffffff;
  position: relative;
  text-decoration: none;
}
.Links a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  bottom: -5px;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.Links a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.logos {
  display: inline-flex;
  align-items: flex-start;
  padding-left: 25px;
  gap: 10px;
}

.Links {
  display: inline-flex;
  align-items: flex-start;
  padding-right: 25px;
  gap: 30px;
}

.text-container {
  background-color: rgba(0, 0, 0, 0.7); /* Black with 50% transparency */
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px; /* Add some space between sections */
}

.hamburger {
  background: none;
  border: none;
  padding-top: 0px;
  font-size: 1.5em;
  cursor: pointer;
  color: white;
  display: none;
}


.dropdown-content.active {
  display: flex;
  flex-direction: column;
  width: 150px;
}

.dropdown-content {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: #333;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  text-align: center;
}

.dropdown-content a {
  padding: 12px 16px;
  color: #ffffff;
  display: block; /* Make sure the anchor behaves as a block-level element */
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
  overflow: hidden; /* Ensures the pseudo-element does not extend beyond the link's area */
}

.dropdown-content a::before {
  content: '';
  display: block;
  width: 0; /* Start with a width of 0 */
  position: absolute;
  height: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  bottom: 0;
  left: 16px;
  right: 16px;
  transition: width 0.3s ease-in-out; /* Animate the width and horizontal position */
}

.dropdown-content a:hover::before {
  width: calc(100% - 32px); /* On hover, extend the width to 100% */
}

@media all and (max-width: 768px) {
  nav {
    width: 100%;
  }
  nav .Links a {
      display: none;
  }
  
  .hamburger {
      display: block;
  }

  .dropdown-content {
    width: 100%;
  }

  .logos {
    display: flex;  /* added this line */
  }

  
}

@media all and (max-width: 768px) {
  .text-container {
      padding: 20px;
      margin-bottom: 10px;
  }
}

@media all and (min-width: 768px) {
  .dropdown-content {
    display: none !important; /* Forces the dropdown to hide on wider screens */
  }
}