.admin-login {
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    text-align: center;
}

.admin-login input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.admin-panel {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
}

.admin-panel form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    padding: 0 20px;
}

.admin-header h2 {
    color: white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    font-size: 24px;
}

.signout-btn {
    background-color: #d32f2f;
    margin-left: auto;
}

.delete-selected-btn {
    background-color: #d32f2f;
    margin: 20px 0;
}

button {
    padding: 10px 20px;
    background-color: #022133;
    color: white;
    border: none;
    cursor: pointer;
}

button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.upload-button {
    background-color: #cccccc;
    transition: background-color 0.3s ease;
}

.upload-button.active {
    background-color: #2196F3;
}

.upload-button:hover.active {
    background-color: #1976D2;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    margin-top: 30px;
}

.image-item {
    position: relative;
    border: 1px solid #ccc;
    padding: 10px;
}

.image-checkbox {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.image-item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.image-item button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.image-item button:hover {
    background: darkred;
}

@media (min-width: 768px) {
    .image-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1024px) {
    .image-grid {
        grid-template-columns: repeat(6, 1fr);
    }
}

.upload-form {
    max-width: 600px;
    margin: 0 auto;
}

.drop-zone {
    border: 2px dashed #ffffff;
    border-radius: 8px;
    padding: 40px;
    text-align: center;
    margin: 20px 0;
    position: relative;
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.1);
}

.drag-active {
    border-color: #022133;
    background: rgba(255, 255, 255, 0.2);
}

.drop-zone-content {
    color: white;
}

.drop-zone svg {
    margin-bottom: 15px;
}

.file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.selected-file {
    margin-top: 10px;
    font-size: 0.9em;
    color: #a0a0a0;
}

.progress-bar {
    width: 100%;
    height: 6px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    margin: 10px 0;
    visibility: hidden;
}

.progress-bar.visible {
    visibility: visible;
}

.progress-fill {
    height: 100%;
    background: #2196F3;
    border-radius: 3px;
    transition: width 0.3s ease;
}

.progress-bar span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 12px;
}

.delete-button {
    --background: #2B3044;
    --background-hover: #1E2235;
    --text: #fff;
    --shadow: rgba(0, 9, 61, .2);
    --paper: #5C86FF;
    --paper-lines: #fff;
    --trash: #E1E6F9;
    --trash-lines: #BBC1E1;
    --check: #fff;
    --check-background: #5C86FF;

    position: relative;
    border: none;
    outline: none;
    background: var(--background);
    padding: 10px 24px;
    border-radius: 7px;
    min-width: 142px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--text);
    transition: transform .3s, box-shadow .3s, background .3s;
    box-shadow: 0 4px 8px var(--shadow);
}

.delete-button span {
    font-size: 14px;
    line-height: 25px;
    font-weight: 600;
    opacity: 1;
    transition: transform .4s ease .2s, opacity .3s ease .2s;
}

.delete-button .trash {
    position: relative;
    left: -8px;
    transform: scale(0.64);
}

.delete-button .trash .top {
    position: absolute;
    width: 28px;
    height: 40px;
    bottom: 100%;
    transform: translateY(2px);
}

.delete-button .trash .box {
    width: 20px;
    height: 25px;
    border: 2px solid var(--trash);
    border-radius: 1px 1px 4px 4px;
    position: relative;
    z-index: 2;
}

/* Add all the animation keyframes */
@keyframes paper {

    10%,
    100% {
        opacity: 1;
    }

    20% {
        transform: translateY(-16px);
    }

    40% {
        transform: translateY(0);
    }

    70%,
    100% {
        transform: translateY(24px);
    }
}

@keyframes cut {

    0%,
    40% {
        transform: translate(-0.5px, -16px) scaleX(0.5);
    }

    100% {
        transform: translate(-0.5px, 24px) scaleX(0.5);
    }
}

.delete-button.deleting {
    --btn: var(--background-hover);
    --shadow-y: 5px;
    --shadow-blur: 9px;
}

.login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    padding: 40px;
    transform: translate(-50%, -50%);
    background: rgba(2, 33, 51, 0.8);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
    border-radius: 10px;
}

.login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
}

.login-box .user-box {
    position: relative;
}

.login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
}

.login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
}

.login-box .user-box input:focus~label,
.login-box .user-box input:valid~label {
    top: -20px;
    left: 0;
    color: #2196F3;
    font-size: 12px;
}

.login-box button {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    background: transparent;
    color: #2196F3;
    font-size: 16px;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 4px;
    border: none;
    cursor: pointer;
}

.login-box button:hover {
    background: #2196F3;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #2196F3,
        0 0 25px #2196F3,
        0 0 50px #2196F3,
        0 0 100px #2196F3;
}

.login-box button span {
    position: absolute;
    display: block;
}

.login-box button span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #2196F3);
    animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
    0% {
        left: -100%;
    }

    50%,
    100% {
        left: 100%;
    }
}

.login-box button span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #2196F3);
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s;
}

@keyframes btn-anim2 {
    0% {
        top: -100%;
    }

    50%,
    100% {
        top: 100%;
    }
}

.login-box button span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #2196F3);
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s;
}

@keyframes btn-anim3 {
    0% {
        right: -100%;
    }

    50%,
    100% {
        right: 100%;
    }
}

.login-box button span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #2196F3);
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s;
}

@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }

    50%,
    100% {
        bottom: 100%;
    }
}

.progress-container {
    position: relative;
    width: 100%;
    margin: 20px 0;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.progress-container.visible {
    opacity: 1;
}

.progress-bar {
    width: 100%;
    height: 8px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}

.progress-fill {
    height: 100%;
    background: linear-gradient(90deg, #1976D2, #2196F3);
    border-radius: 4px;
    transition: width 0.3s ease;
}

.progress-percentage {
    position: absolute;
    top: -25px;
    right: 0;
    background: #2196F3;
    color: white;
    padding: 2px 8px;
    border-radius: 3px;
    font-size: 12px;
    transform: translateX(50%);
    transition: left 0.3s ease;
}

.progress-percentage::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px;
    border-style: solid;
    border-color: #2196F3 transparent transparent transparent;
}

.upload-status {
    color: white;
}

.storage-info {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 40px;
    text-align: center;
    width: 200px;
}

.storage-info p {
    color: white;
    font-size: 12px;
    margin: 5px 0;
}

.storage-bar {
    width: 100%;
    height: 4px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    overflow: hidden;
}

.storage-used {
    height: 100%;
    background: linear-gradient(90deg, #2196F3, #64B5F6);
    transition: width 0.3s ease;
}

.storage-used.warning {
    background: linear-gradient(90deg, #FFA726, #FF7043);
}

.storage-used.critical {
    background: linear-gradient(90deg, #EF5350, #E53935);
}

.file-limit-info {
    color: #a0a0a0;
    font-size: 0.8em;
    margin-top: 5px;
}

.file-error {
    color: #ff5252;
    font-size: 0.9em;
    margin-top: 10px;
    white-space: pre-line;
}

.signout-container {
    position: relative;
    margin-left: auto;
}