.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Change justify-content to flex-start */
    height: 1024px;
    background-image: url(../images/additional-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    mix-blend-mode: lighten;
    color: #feffdc;
}

.container h2 {
    font-size: 2.5em;
    margin-top: 0;
    /* Remove default margin */
    text-align: center;
    /* Center align the text */
    padding: 50px 0;
    /* Add padding to the heading */
}

.service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    /* Full width for the service item */
    padding: 20px 0;
    /* Add vertical padding */
    text-align: center;
    /* Center align the text */
}

.clickable-title {
    cursor: pointer;
    transition: color 0.3s ease;
}

.clickable-title:hover {
    color: #ffd700;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90vh;
}

.modal-content img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
}

.modal-close {
    position: absolute;
    top: -40px;
    right: -40px;
    background: none;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
}

.view-booth-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #feffdc;
    color: #feffdc;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.view-booth-btn:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
}

@media all and (max-width: 768px) {
    .container {
        height: 100%;
    }

    .service-item {
        width: 100%;
    }

    .modal-close {
        top: -30px;
        right: 0;
    }
}