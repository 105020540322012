.carousel {
  width: 1000px;
  height: 600px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.carousel-images {
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  will-change: transform;
}

@media all and (max-width: 768px) {
  .carousel {
    width: 100%;
    height: 300px;
  }
}