/* Base container for contact section with styling */
.contact-container {
    font-family: Arial, sans-serif;
    padding: 50px;
    text-align: center;
    background-color: #022133;
    color: #fff;
}

/* Card-like design for the contact form and information */
.card {
    display: flex;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
    /* Centers the card on the page */
}

/* Information section of the card, with background image and styling */
.info-section {
    width: 30%;
    padding: 30px;
    background-image: url("../images/contact.png");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: top;
    color: #fff;
}

/* Container for the text in the information section */
.text-container-info {
    width: 350px;
    height: 180px;
    position: absolute;
    flex-shrink: 0;
    border-radius: 25px;
    background: rgba(0, 0, 0, 0.70);
    /* Semi-transparent background */
}

/* Paragraph styling in the information section */
.text-container-info p {
    font-size: 16px;
    color: #fff;
    padding: 5px;
    text-align: center;
}

/* Styling for the form section of the card */
.form-section {
    width: 50%;
    flex: 1;
    padding: 30px;
    background-color: #fff;
}

/* Row for input fields in the form for better structure */
.input-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

/* Individual input field styling, with responsive widths */
.input-row input {
    width: 48%;
}

.input-row input:not(:last-child) {
    margin-right: 2%;
}

/* Base styles for all input fields and textareas within the form */
.form-section input,
.form-section textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

/* Styling for input fields and textareas when focused */
.form-section input:focus,
.form-section textarea:focus {
    border-color: #5643fa;
    box-shadow: 0 0 8px rgba(86, 67, 250, 0.2);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Submit button styling */
button {
    background-color: #5643fa;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

/* Confirmation message styling after form submission */
.confirmation-message {

    padding: 10px;
    margin: 10px 0;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #d1ffd1;
    color: #1a721a;
    font-weight: bold;
}

.form-section {
    width: 50%;
    flex: 1;
    padding: 30px;
    background-color: #fff;
    min-height: 500px;
    /* Set the min-height to the height of the form section */
}

/* Media query for mobile and tablet adjustments */
@media all and (max-width: 1320px) {

    /* Contact container adjustments for smaller screens */
    .contact-container {
        padding: 0;
    }

    .text-container-info {
        right: calc(50% - 175px);
        width: 350px;
        height: 180px;
        ;
    }

    .text-container-info p {
        font-size: 14px;
    }

    .contact-info {
        /* padding: 10px; */
        font-size: 14px;
        text-align: center;
    }


    .input-field {
        width: auto;
        margin: 5px 0;
    }

    /* Card layout changes to column for stacking on smaller screens */
    .card {
        flex-direction: column;
    }

    /* Info section takes full width on smaller screens */
    .info-section {
        width: 100%;
        height: 350px;
    }

    form {
        width: calc(100% - 100px);
    }

    .input-row {
        justify-content: space-between;
        flex-wrap: wrap;

    }


    .form-section {
        width: 100%;
        height: 350px;
    }

    .confirmation-message {
        width: calc(100% - 100px);
    }
}